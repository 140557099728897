import {createSlice} from "@reduxjs/toolkit";
import {createApi} from "@reduxjs/toolkit/query/react";
import {ApiEnum} from "../../common/enums/ApiEnum";
import {
    Converted,
    Image,
    ProductDetailsDataInterface,
    ProductDownload,
    Video
} from "../../common/interfaces/ProductDetailsDataInterface";
import {apiBaseQuery} from "../../common/utils/apiBaseQuery";
import {LanguageInterface} from "../../common/interfaces/LanguageInterface";
import {ProductRangeDataInterface} from "../../common/interfaces/ProductRangeDataInterface";

interface ProductDetailsPageInterface {
    loading: boolean,
}

const initialState: ProductDetailsPageInterface = {
    loading: false,
}


const productDetailsSlice = createSlice({
    name: 'productDetails',
    initialState,
    reducers: {},
})

interface Args {
    productId: string
    products?: ProductRangeDataInterface
    accessories?: ProductRangeDataInterface
    language: LanguageInterface | null
}

export const productDetailsApiSlice = createApi({
    reducerPath: "productDetailsApi",
    baseQuery: apiBaseQuery(),
    tagTypes: [],
    endpoints: (builder) => ({
        getProductDetails: builder.query<ProductDetailsDataInterface, Args>({
            query: (args) => ApiEnum.PRODUCT_DETAILS + "/" + args.productId,
            transformResponse: (response: ProductDetailsDataInterface, _, args: Args) => {

                if ((args.products && args.products.products.length > 0) || (args.accessories && args.accessories.products.length > 0)) {
                    response.productAssociations = Object.keys(response.productAssociations).reduce((accumulator: any, associationKey) => {
                        const association = response.productAssociations[associationKey as keyof typeof response.productAssociations];
                        accumulator[associationKey] = [
                            ...(args.products?.products.filter(product => association.includes(product.identifier)) || []),
                            ...(args.accessories?.products.filter(accessory => association.includes(accessory.identifier)) || [])
                        ];
                        return accumulator;
                    }, {});
                }

                response.productDownloads.map(download => {
                    const downloadsFormatted: ProductDownload[] = []
                    Object.values(download.value).forEach((value: any) => {
                            if (args.language && value[args.language.localeCode]) {
                                downloadsFormatted.push(value[args.language.localeCode])
                            }
                        }
                    )
                    download.value = downloadsFormatted
                    return download
                })

                let formattedResponse = response
                let marketingDownloadsCount = 0
                let technicalDownloadsCount = 0
                const marketingDownloadsItems = ['images', 'videos', 'brochures']
                const marketingDownloads: ProductDownload[] = []

                response.productDownloads.forEach(download => {
                    if (marketingDownloadsItems.includes(download.code)) {
                        marketingDownloads.push(download);
                        download.value.forEach((_: ProductDownload) => {
                            marketingDownloadsCount++
                        })
                    }
                })
                formattedResponse.marketingDownloads = marketingDownloads
                let marketingNavItems = ['all']
                marketingDownloads.map(item => item.value.length > 0 ? marketingNavItems.push(item.code) : false)
                formattedResponse.marketingDownloadNavItems = marketingNavItems

                const technicalDownloadsItems = [
                    'manuals', 'exploded_views', 'service', 'software',
                    'special_codes', 'troubleshooting', 'certificates',
                    'drawings', 'electrical_diagrams', 'bin_files', 'other'
                ]
                const technicalDownloads: ProductDownload[] = []

                response.productDownloads.forEach(download => {
                    if (technicalDownloadsItems.includes(download.code)) {
                        technicalDownloads.push(download)
                        download.value.forEach((_: ProductDownload) => {
                            technicalDownloadsCount++
                        })
                    }
                })
                formattedResponse.technicalDownloads = technicalDownloads

                let technicalNavItems = ['all']
                technicalDownloads.forEach(item => {
                    if (item.value.length > 0) {
                        switch (item.code) {
                            case 'manuals':
                            case 'service':
                            case 'troubleshooting':
                                return !technicalNavItems.includes('manuals') ? technicalNavItems.push('manuals') : false
                            case 'drawings':
                            case 'electrical_diagrams':
                            case 'exploded_views':
                                return !technicalNavItems.includes('drawings') ? technicalNavItems.push('drawings') : false
                            case 'software':
                            case 'special_codes':
                            case 'bin_files':
                                return !technicalNavItems.includes('software') ? technicalNavItems.push('software') : false
                            case 'certificates':
                                return !technicalNavItems.includes('certificates') ? technicalNavItems.push('certificates') : false
                            case 'videos':
                                return !technicalNavItems.includes('videos') ? technicalNavItems.push('videos') : false
                            default:
                                return false
                        }
                    }
                })
                formattedResponse.technicalDownloadNavItems = technicalNavItems

                let imagesQHD: Image[] = []
                response.productImages.forEach(image => {
                    imagesQHD.push(
                        {type: 'image', label: image.label, url: image.converted.QHD, converted: {} as Converted}
                    )
                    marketingDownloadsCount++
                })

                formattedResponse.marketingDownloads.push({
                    code: 'images', type: 'downloads', label: 'Images', value: imagesQHD
                })
                const videosMarketing: Video[] = []
                const videosTech: Video[] = []
                response.productVideos.forEach(video => {
                    // video names starting with MK are technical videos
                    if (args.language && video.label[args.language.localeCode] && video.label[args.language.localeCode].startsWith('MK')) {
                        videosTech.push(
                            {type: 'video', label: video.label[args.language.localeCode], url: video.url}
                        )
                        technicalDownloadsCount++
                    } else {
                        if (args.language && video.label[args.language.localeCode]) {
                            videosMarketing.push(
                                {type: 'video', label: video.label[args.language.localeCode], url: video.url}
                            )
                            marketingDownloadsCount++
                        }
                    }
                })

                formattedResponse.marketingDownloads.push(
                    {code: 'videos', type: 'downloads', label: 'Videos', value: videosMarketing}
                )
                formattedResponse.technicalDownloads.push(
                    {code: 'videos', type: 'downloads', label: 'Videos', value: videosTech}
                )

                formattedResponse.downloadsCount = {
                    marketing: marketingDownloadsCount,
                    technical: technicalDownloadsCount
                }

                return formattedResponse
            }
        }),
    }),
});

export const productDetailsReducer = productDetailsSlice.reducer;
export const {useGetProductDetailsQuery} = productDetailsApiSlice;
